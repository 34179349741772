import { Layout, Loader } from './utils/generals';
import { useRef } from 'react'; 

import { router } from './router';
import { useMain } from './utils/useMain';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
   const elementRef = useRef(null);
   const [ main ] = useMain();

   setTimeout(()=>{
      const divElement = elementRef.current;
      if(divElement !== null ){
        divElement.classList.add('fade-in');
        divElement.classList.remove('body');
      }
    }, 500);

    let link = document.location.pathname;
    let finalView

    if( main !== null ){
      let view = main.links.filter(i => i.link_translated === link );
      finalView = view[0].component || "NotFound";

    }

    return (
        <div className='body' ref={elementRef}>
          <Layout  className={'containerLayout px-0'} >
                { main !== null ?
                  <div>                  
                    {router[finalView]}
                  </div>
                  :
                  <Loader />
                }
            </Layout>
        </div>
    );
}

export default App;
