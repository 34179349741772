import { useState } from 'react';

export const useGlobal = () => {
    const [ global, setGlobal ] = useState( () => {
        let re = JSON.parse( localStorage.getItem('global') )
        return re;
        }
    ); 
    if(global === null){
        let serverDomain;
        let domain;
        if(window.location.hostname === 'localhost'){
            serverDomain = window.location.protocol + '//api.anitacode.com';
            domain = window.location.protocol + '//localhost:3000';
        }else{
            serverDomain = window.location.protocol + '//api.anitacode.com';
            domain =  window.location.protocol + '//' + window.location.hostname;
        }

        let object = {
            domain : domain,
            serverDomain : serverDomain
        };

        localStorage.setItem('domain', JSON.stringify({ object }));
        setGlobal(object)
    }
    
    return global;
}
