import { useEffect, useState } from 'react';
import { 
    Row, 
    Alert, 
    Container, 
    Spinner, 
    Nav, 
    Col, 
    Offcanvas, 
    Button, 
    Carousel 
} from 'react-bootstrap';
import axios from 'axios';
import { List } from 'react-bootstrap-icons';

import { useMain } from '../utils/useMain';
import { useLang } from '../utils/useLang';
import { useGlobal } from '../utils/useGlobal';
import { useAlertMessage } from '../utils/useAlertMessage';

export const Loader = () => {
    return (
        <div className={'py-5 text-center'}>
            <Spinner className={'pink'} animation="border" role="status" />
        </div>
    )
}

export const SuperTitle = ( { value, colorClass, shadowClass, hoverClass } ) =>{

    let chars = value.split('');
    return (
        <div 
            className={'jumbo mb-3'}
            style={
                {
                    marginTop : '-27px'
                }
            }
        > 
            { chars.map( (i,index) => {
                    return  <h1 
                                key={ 'chars' + index } 
                                className={colorClass + ' ' + shadowClass + ' ' + hoverClass + ' jumbo boing_hover d-inline-block mb-0'}
                                >{i}</h1>;
                })
            }
            <h1 className={'pulse d-inline-block jumbo lighter-gray bold'}>_</h1>
        </div>
    )
}

export const LoaderSm = () => {
    return (
        <div className={'py-0 text-center'}>
            <Spinner className={'pink'} animation="border" role="status" />
        </div>
    )
}

export const Translate = ( { alias } ) => {
    const [ main ] = useMain();
    let trans = main.translations.filter(f => f.alias === alias)
    if(trans[0] !== undefined && trans[0].value){
        return trans[0].value;
    }else{
        return alias;
    }
}

export const translate = ( alias, main ) => {
    let trans = main.translations.filter(f => f.alias === alias);
    if(trans[0] !== undefined && trans[0].value){
        return trans[0].value;
    }else{
        return alias;
    }
}

export const Contact =  () => {
    const [ main ] = useMain();
    const global = useGlobal();
    const [ name , setName ] = useState("");
    const [ email , setEmail ] = useState("");
    const [ phone , setPhone ] = useState("");
    const [ message , setMessage ] = useState("");
    const [ loadingSent , setLoadingSent ] = useState(false);
    
    const [ msg, setMsg ] = useState(null); 

    const submitValidate = async () => {
        setLoadingSent(true);
        if(
            name !== "" &&
            email !== "" &&
            phone !== "" &&
            message !== ""
        ){
            const sent = { 
                name: name,
                email: email,
                phone: phone,
                message: message
            };

            let headers = {
                'Content-Type' : 'text/plain'
            }

            const response = await axios.post(global.serverDomain + '/api/contact', sent, headers);
         
            if(response.status === 200){
                setMsg('Message received!');
            }else{
                setMsg('Error.');
            }
            setLoadingSent(false);
        }else{
            setMsg('All fields requiered');

            setLoadingSent(false);
        }
    }
    return (
        <div id={'contact'}>
         { main !== null ? 
            <div className={'bg-light p-5'}>
                <Row className={'w-100 py-5'}>
                    <Col>
                        <h3 className={'light-gray shadow-white'}>~$ composer <Translate alias={'let-s-chat'} /> <span className={'pulse medium-gray bold  shadow-white'}>_</span></h3>
                        <label><Translate alias={'complete-name'} /></label>
                        <input 
                            className={'form-control mb-3'} 
                            value = {name}
                            onChange = { (e) =>setName( e.target.value ) }
                        />
                        <label><Translate alias={'email'} /></label>
                        <input 
                            className={'form-control mb-3'}
                            value = {email}
                            onChange = { (e) =>setEmail( e.target.value ) }
                        />
                        <label><Translate alias={'phone'} /></label>
                        <input 
                            className={'form-control mb-3'} 
                            value = {phone}
                            onChange = { (e) =>setPhone( e.target.value ) }
                        />
                        <label><Translate alias={'message'} /></label>
                        <input 
                            className={'form-control'} 
                            value = {message}
                            onChange = { (e) =>setMessage( e.target.value ) }
                        />
                        {msg !== null && <div className={'alert alert-warning mt-4 mb-0'}>{msg}</div>}
                        <Button 
                            disabled={loadingSent}
                            onClick = { () => submitValidate() }
                            size={'lg'}
                            variant={'outline-primary'}
                            className={'mt-4'}
                        >
                            {
                                loadingSent 
                                ? 
                                    <LoaderSm />
                                : 
                                    <Translate alias={'send'}/>
                            }
                            
                        </Button>
                    </Col>

                    <Col className={'mt-5 mt-sm-0'} >
                        <h3 className={'light-gray shadow-white'}>~$ yarn add <Translate alias={'connect-with-anita'}/> <span className={'pulse medium-gray bold shadow-white'}>_</span></h3>
                        <Nav className={'justify-content-start fs-8'} >
                            { main.socials.map( item => <Nav.Item key={ 'socials'+item.id }><Nav.Link className={'text-uppercase'} href={item.link} target={'_blank'}>{ item.shortname }</Nav.Link></Nav.Item>)}
                        </Nav>
                    </Col>
                </Row>
            </div>
            : <Loader/>
        }
        </div>
    );
}

export const openGalleryModal = ( item, setModalShow, handleShow, global ) => {
        
    let gal = <Carousel fade>
        <Carousel.Item
            className={'text-center bg-light'}
            style={{
                height:'80vh'
            }}
        >
            <div
                style={
                    {
                        width:'100%',
                        height:'100%',
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: "url("+global.serverDomain + item.mainImg.image+")"
                    }
                }
            >
            </div>
            <Carousel.Caption>
            <h4 className={'m-0 p-0'}>{item.name_translated}</h4>
            </Carousel.Caption>
        </Carousel.Item>
        {
            Object.keys(item.images).map( i => {
                return <Carousel.Item key={'imgaport'+ item.images[i].id } className={'text-center bg-light'}
                    style={{
                        height:'80vh'
                    }}
                >
                    <div
                        style={
                            {
                                width:'100%',
                                height:'100%',
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                backgroundImage: "url("+global.serverDomain + item.images[i].image+")"
                            }
                        }
                    >
                    </div>
                    <Carousel.Caption>
                        <h4 className={'m-0 p-0'}>{item.name_translated}</h4>
                    </Carousel.Caption>
                </Carousel.Item>
            }) 
        }
    </Carousel>;

    setModalShow({ header : item.name_translated, body : gal})
    handleShow();
}

export const AboutModule = ( ) => {
    const [ main ] = useMain();
    return (
        <div className={'py-5 bg-medium-pink'}>
            {main === null ? 
                <Loader/> 
            :
                <Row className={'w-100 py-5 px-4'}>
                    <Col className={'justify-content-md-end text-md-end'} sm={3}>
                        <h2 className={'light-pink shadow-black-sm'}>~$ npm test</h2>
                    </Col>
                    <Col sm={8} md={7} lg={8}>
                        <SuperTitle 
                                value = {main.aboutme.title_translated}
                                colorClass = { 'text-white' }
                                shadowClass = { 'shadow-black-sm'}
                                hoverClass = { 'blue_hover'}
                            /> 

                        <p className={'light-pink shadow-black-sm'}> {main.aboutme.content_translated}</p>
                    </Col>
                </Row>
            }
            
        </div>
    )
} 


export const Layout = ( { className, children } ) => {
    const [ show , setShow ] = useState( false );
    const [ lang, setLang ] = useLang();
    const [ alertMessage ] = useAlertMessage();
    const [ loading , setLoading ] = useState( false );

    const [ main, setMain ] = useMain();
    const global = useGlobal();

    let link = window.location.pathname;

    const loadMainContent = async () =>{        
        setLoading(false);
    }

    useEffect( () => loadMainContent(), [ main, loading, lang ] );

    return (
        <Container fluid className={className}>
            { alertMessage !== null && <Alert>{alertMessage}</Alert> }
            { !loading && global !== null && main !== null ? 
                    <Row className={'g-0'} >
                        <Col>
                            <header className={'pt-3'}>
                                <Row className={'g-0 pb-2'}>
                                    <Col xs={4} md={2} lg={2} xl={1}>
                                        <a href={'/'}>
                                            <img alt={main.admin.website} src={global.serverDomain + main.admin.logo}/>
                                        </a>
                                    </Col>
                                    <Col
                                        xs={4}
                                        md={8}
                                        className={'d-flex align-items-center'}
                                    >
                                        <Button 
                                            className={'fs-2'}
                                            variant={'light'}
                                            style={{marginTop:"-10px"}}
                                            onClick={() => setShow(!show)}
                                        >
                                            <List className={'pink'}/>
                                        </Button>
                                        <Offcanvas show={show} onHide={() => setShow( false )}>
                                            <Offcanvas.Header closeButton>
                                                <div></div>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav
                                                    className={'flex-column'}
                                                    activeKey={link}
                                                    >
                                                    { main.links.map( item => {
                                                        return <Nav.Item key={ 'link'+item.id }>
                                                            <Nav.Link
                                                                className={'text-uppercase boing_hover_slow jumbo'} 
                                                                href={ item.link_translated } >
                                                                    { item.name_translated }
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                        }
                                                    )}
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                    </Col>
                                    <Col className={'d-flex justify-content-end align-items-center'}>
                                        <Nav
                                            className={'justify-content-end fs-8'}
                                            activeKey={lang}
                                            onSelect={(selectedKey) => {
                                                setLoading(true)
                                                setMain(null)
                                                setLang(selectedKey)
                                                localStorage.removeItem('main')
                                            }}
                                            >
                                            { main.langs.map( item => <Nav.Item key={ 'lang'+item.id }><Nav.Link className={'text-uppercase'} eventKey={ item.code }>{ item.code }</Nav.Link></Nav.Item>)}
                                        </Nav>
                                    </Col>
                                </Row>
                                <div className={'white-transparent-gardient'}></div>
                            </header>
                            <main>
                                { children }
                            </main>
                            <footer>
                                <Contact content = {main.socials} />
                                <Nav
                                    className={'justify-content-start fs-8'}
                                    activeKey={lang}
                                    onSelect={(selectedKey) => {
                                        setLoading(true)
                                        setLang(selectedKey)
                                        setMain(null)
                                        localStorage.removeItem('main')
                                    }}
                                    >
                                    { main.langs.map( item => <Nav.Item key={ 'lang'+item.id }><Nav.Link className={'text-uppercase'} eventKey={ item.code }>{ item.code }</Nav.Link></Nav.Item>)}
                                </Nav>
                            </footer>
                        </Col>
                    </Row>
                : <Loader />}
        </Container>
        
    );
}