import { useState, useEffect } from 'react';
import axios from 'axios';

import { useLang } from './useLang';
import { useGlobal } from './useGlobal';
import { useAlertMessage } from './useAlertMessage';

export const useMain = () => {

    const global = useGlobal();
    const [ main, setInnerMain ] = useState( () => {
        let re = JSON.parse( localStorage.getItem('main') )
        return re;
        }
    );

    const [ lang ] = useLang();
    const [ , setAlertMessage] = useAlertMessage();

    const setMain = (val) => setInnerMain(val)

    const loadMainContent = async () => {
        if(global !== null){
            let url = global.serverDomain + '/api/main/' + lang;
            const response = await axios.get(url);
            if(response.status === 200 ){
                setMain(response.data.data)
                localStorage.setItem('main', JSON.stringify( response.data.data ));
            }else{
                setAlertMessage('Error')
            }
        }
        
    }

    if(main === null){
        loadMainContent()
    }

    return [ main, setMain ] ;
}
