import { useState, useEffect, useRef } from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { ArrowLeftShort } from 'react-bootstrap-icons';
import {Helmet} from "react-helmet";
import axios from 'axios';


import { Loader, Translate, AboutModule, openGalleryModal } from '../utils/generals';
import { useGlobal } from '../utils/useGlobal';
import { useAlertMessage } from '../utils/useAlertMessage';
import { useLang } from '../utils/useLang';
import { useMain } from '../utils/useMain';
import { useMousePosition } from '../utils/useMousePosition';
import { useModal } from '../utils/useModal';

const ScrollDown = () => {
    return ( 
        <div className={'bounce medium-gray'}>
                <div className={'position-relative'}>
                    <span className={'position-absolute'} style={{bottom:55, right:0}} >
                        <span className={'d-inline-block rot90'}>
                            <ArrowLeftShort size={30} />
                            <Translate alias={"scroll-down"} />
                        </span>
                    </span>
                </div>
        </div>
        );
}

const MainBanner = ({ content, global }) => {
    let heightTotal = window.innerHeight;
    let headerHeight = document.querySelector('header').offsetHeight;
    let height = heightTotal - headerHeight;

    const position = useMousePosition();

    const [ main ] = useMain();
    const mainBanner = useRef();
    const circleFollow = document.getElementById('circleFollow');

    if(mainBanner !== undefined && mainBanner.current !== undefined){
        let mainBannerPosition = mainBanner.current.getBoundingClientRect();
        let widthCircleFollow = circleFollow.offsetWidth / 1.5 ;
        let heightCircleFollow = circleFollow.offsetHeight / 1.5 ;
        if(
            position.x > mainBannerPosition.left && 
            position.x < mainBannerPosition.right &&
            position.y > mainBannerPosition.top &&
            position.y < mainBannerPosition.bottom
          )
        {
            circleFollow.style.opacity = .5
            circleFollow.style.left = position.x - widthCircleFollow + "px"
            circleFollow.style.top = position.y - heightCircleFollow + "px"
        } else {
            circleFollow.style.opacity = 0
        }
    }

    var chars = content.content_translated.split('');

    return (
        <div id={'mainBanner'}
            ref={mainBanner}
            className={'bg-light px-4 px-md-0 position-relative'} style={{height: height}}>
            <div id={'circleFollow'} 
            className={'background-image position-absolute '} 
            style={
                { 
                    width: 650, 
                    height: 650, 
                    opacity: 0,
                    zIndex: 1,
                    top : 10,
                    backgroundSize:"auto 100%",
                    backgroundAttachment: "fixed",
                    backgroundImage : "url(" + global.serverDomain + main.admin.bgimg + ")"
                }
            }>
                <div className={'circle-bg-transparent w-100 h-100 '} style={{margin:0}}>

                </div>
            </div>
            <div 
                className={'d-flex position-relative h-100 align-items-center w-100'}
                style={
                    {
                        zIndex: 1,
                    }
                }
            >
                <Row className={'w-100'}>
                    <Col className={'justify-content-md-end text-md-end'} sm={3}>
                        <h1 className={'light-gray shadow-white'}>~$ echo</h1>
                    </Col>
                    <Col md={7} lg={8}>
                        <h1 className={'medium-gray shadow-white'}>{content.title_translated} <span className={'pulse medium-blue bold shadow-white'}>_</span></h1>
                        { chars.map( (i,index) => {
                                return  <h1 
                                            key={ 'chars' + index } 
                                            className={'jumbo medium-gray shadow-white-lg boing_hover blue_hover d-inline-block mb-0'}
                                            >{i}</h1>;
                            })
                        }
                        <p className={'medium-gray'}>FrontEnd Developer & BackEnd.</p>
                        <div>
                        <Button href={'#contact'} size={'lg'} variant="outline-primary"><Translate alias={'contact-me'}/></Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={'position-absolute w-100 '} style={{bottom: 15, zIndex: 2 }}>
                <ScrollDown/>
            </div>
        </div>
    );
}

const Portfolio = ( { content } ) => {
    const global = useGlobal();
    const [ main ] = useMain();
    const [ modal, setModalShow, show, handleHide, handleShow ] = useModal();
    let link = main.links.filter(it =>it.id === 2)[0];

    return (
        <div className={'pt-5 bg-dark-blue'}>
            {global !== null 
            ? 
                <>
                    <div id={'modalspace'}>
                        <Modal
                            indicators={false}
                            size={'xl'}
                            onHide={ handleHide }
                            show = {show}
                            centered
                        >
                        <Modal.Body >
                            {modal && modal.body}
                        </Modal.Body>
                        </Modal>
                    </div> 
                    <Row className={'w-100 py-5 px-4'}>
                        <Col className={'justify-content-md-end text-md-end'} sm={3}>
                            <h2 className={'light-gray shadow-black'}>~$ cd .. /</h2>
                        </Col>
                        <Col sm={8} md={7} lg={8}>
                            <h2 className={'medium-blue shadow-black'}>{content.text.title_translated} <span className={'pulse lighter-gray bold'}>_</span></h2>
                            <p className={'lighter-gray shadow-black-sm'}> {content.text.content_translated} <Translate alias={'if-you-are-interested-you-can-visit'} /> <a href={global.domain + link.link_translated}><Translate alias={'my-portfolio'} /></a></p>
                        </Col>
                    </Row>
                    <Row className={'w-100 g-0 pt-5'}>
                        { 
                            content.items.map( i => {
                                return <Col xs={12} md={3} key={'imgport'+i.id}>
                                    <div className={'position-relative mosaic-portfolio'}>
                                        <div className={'transparent-image-top'}></div>
                                        <div className={'bg-background-image img-mosaic h-100 position-absolute w-100'} style={{backgroundImage:"url(" + global.serverDomain + i.mainImg.image + ")"}}>
                                            
                                        </div>
                                        <Button
                                                onClick={() => openGalleryModal(i, setModalShow, handleShow, global ) }
                                                size={'lg'} 
                                                variant="primary"
                                            >
                                                <Translate alias={'view'}/>
                                            </Button>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </>
            
            : <Loader />}
            
        </div>
    )
} 

const Skills = ( {content} ) => {
    return (
        <div className={'py-5 bg-white'}>
            <Row className={'py-5 px-4 w-100'}>
                <Col className={'justify-content-md-end text-md-end'} sm={3}>
                    <h2 className={'light-pink'}>~$ anita --v</h2>
                </Col>
                <Col sm={8} md={7} lg={8}>
                    <h2 className={'medium-pink'}>skills <span className={'pulse light-pink bold'}>_</span></h2>
                    <p className={'light-pink'}> {content.content_translated}</p>
                    <Row>
                        {
                            content.categories.map(i => {
                                let finalSkills = content.skills.filter(e => e.catid === i.id);
                                return <Col sm={6} key={'cat' + i.id }>
                                        <h4 className={'medium-blue mt-4 mb-4'}>{i.name_translated}</h4>
                                        { 
                                            finalSkills.map( e => <p className={'m-0'} key={'skills' + i.id + e.id}>_ {e.name}</p>)
                                        }
                                    
                                    </Col>
                            })
                        }
                        
                    </Row>
                </Col>
            </Row>
        </div>
    )
} 

export const Home = () => {
    const global = useGlobal();
    const [ main ] = useMain();
    const [ lang ] = useLang();

    const [ home, setHome ] = useState(null)
    const [ , setAlertMessage] = useAlertMessage();

    const loadMainContent = async () => {
        let url = global.serverDomain + '/api/home/' + lang;
        const response = await axios.get(url);
        if(response.status === 200 ){
            setHome(response.data)
        }else{
            setAlertMessage('Error')
        }
    }

    useEffect(() => loadMainContent(), []);

    return (
        <div>

            {
                home === null ? 
                    <Loader />
                :
                <div>

                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{main.admin.website}</title>
                        <meta name="description" content={main.admin.description_tranlation} />
                        <meta name="keywords" content={main.admin.keywords_tranlation} />
                    </Helmet>

                    <MainBanner content = { home.greetings } global = {global} />
                    <Portfolio content = { { text: home.portfoliotext, items: home.portfolio_items } } />
                    <AboutModule content = { home.aboutme } />
                    <Skills content = { { skills: home.skills, categories: home.skills_categories} } />
                    
                </div>
            }
        </div>
    )
}