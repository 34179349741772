import { useState, useEffect } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import axios from 'axios';


import { AboutModule, Loader, Translate } from '../utils/generals';
import { useMain } from '../utils/useMain';
import { useGlobal } from '../utils/useGlobal';
import { useAlertMessage } from '../utils/useAlertMessage';
import { useLang } from '../utils/useLang';

const Skills = ( {content} ) =>{    
    return <Row className={'py-5 px-4 w-100'}>
        <Col className={'justify-content-md-end text-md-end'} sm={3}>
            <h2 className={'light-pink'}>~$ anita --v</h2>
        </Col>
        <Col sm={8} md={7} lg={8}>
            <h2 className={'medium-pink'}>skills <span className={'pulse light-pink bold'}>_</span></h2>
            <p className={'light-pink'}> {content.content_translated}</p>
            <Row>
                {
                    content.categories.map(i => {
                        let finalSkills = content.skills.filter(e => e.catid === i.id);
                      
                        return <Col xs={12} key={'cat' + i.id }>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <h4 className={'medium-blue mt-4 mb-4'}>{i.name_translated}</h4>
                                    </Col>
                                    <Col>
                                    
                                    </Col>
                                </Row>
                                
                                { 
                                    finalSkills.map( e => {
                                        return (
                                            <Row key={'skills' + i.id + e.id} className={'mb-3'}>
                                                <Col>
                                                    <p className={'m-0'} >_ {e.name}</p>
                                                    <p className={'light-gray m-0'}><Translate alias={'since'}/> {e.since}</p>
                                                </Col>
                                                <Col>
                                                    <ProgressBar variant={i.colorclass} now={e.level} />
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            
                            </Col>
                    })
                }
                
            </Row>
        </Col>
    </Row>
}

export const About = () => {
    const [ main ] = useMain();
    const global = useGlobal();
    const [ lang ] = useLang();

    const [ about, setAbout ] = useState(null);
    const [ , setAlertMessage] = useAlertMessage();

    const loadAboutContent = async () => {
        let url = global.serverDomain + '/api/about/' + lang;
        const response = await axios.get(url);
        if(response.status === 200 ){
            setAbout(response.data)
        }else{
            setAlertMessage('Error')
        }
    }

    useEffect(() => loadAboutContent(), [])

    return (
        <div> 
            {global !== null && about !== null ?
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{main.aboutme.title_translated} - {main.admin.website}</title>
                        <meta name="description" content={main.admin.description_tranlation} />
                        <meta name="keywords" content={main.admin.keywords_tranlation} />
                    </Helmet>
                    <div
                        className={'w-100 bg-light-grey'} 
                        style={
                            { 
                            
                                height: '20vh',
                                backgroundSize:"100% auto",
                                backgroundPosition: "center center",
                                backgroundImage : "url(" + global.serverDomain + main.admin.bgimg + ")"
                            }
                        }>
                        </div>

                        <AboutModule />

                        <Skills content = { { skills: about.skills, categories: about.skills_categories} } />
                </div>
            : 
                <Loader/>
            }
        </div>
    )
}