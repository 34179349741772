import { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

import { Loader, openGalleryModal, Translate as Translation, SuperTitle } from '../utils/generals';
import { useGlobal } from '../utils/useGlobal';
import { useMain } from '../utils/useMain';
import { useModal } from '../utils/useModal';
import {Helmet} from "react-helmet";
import { useAlertMessage } from '../utils/useAlertMessage';
import axios from 'axios';
import { useLang } from '../utils/useLang';

const RenderPortfolio = ({ content, global, main }) => {
    const [ modal, setModalShow, show, handleHide, handleShow ] = useModal();
    return (
        <div className={'pt-5 bg-dark-blue'}>
            {global !== null 
            ? 
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{content.portfoliotext.title_translated + ' - ' + main.admin.website}</title>
                        <meta name="description" content={main.admin.description_tranlation} />
                        <meta name="keywords" content={main.admin.keywords_tranlation} />
                    </Helmet>
                    <div id={'modalspace'}>
                        <Modal
                            size={'xl'}
                            onHide={ handleHide }
                            show = {show}
                            centered
                        >
                        <Modal.Body >
                            {modal && modal.body}
                        </Modal.Body>
                        </Modal>
                    </div> 
                    <Row className={'w-100 py-5 px-4 px-md-0'}>
                        <Col className={'justify-content-md-end text-md-end'} sm={3}>
                            <h2 className={'light-gray shadow-black'}>~$ cd .. /</h2>
                        </Col>
                        <Col sm={8} md={7} lg={8}>
                            <SuperTitle 
                                value = {content.portfoliotext.title_translated}
                                colorClass = {'medium-pink'}
                                shadowClass = { 'shadow-white'}
                                hoverClass = { 'blue_hover'}
                            /> 
                            <p className={'lighter-gray shadow-black-sm'}> {content.portfoliotext.content_translated}</p>
                        </Col>
                    </Row>
                    <Row className={'w-100 g-0 pt-5'}>
                        { 
                            content.portfolio_items.map( i => {
                                return <Col xs={12} md={3} key={'imgport'+i.id}>
                                    <div className={'position-relative mosaic-portfolio'}>
                                        <div className={'transparent-image-top'}></div>
                                        <div className={'bg-background-image img-mosaic h-100 position-absolute w-100'} 
                                            style={{backgroundImage:"url(" + global.serverDomain + i.mainImg.image + ")"}}>
                                            
                                        </div>
                                        <Button
                                                onClick={() => openGalleryModal(i, setModalShow, handleShow, global) }
                                                size={'lg'} 
                                                variant="primary"
                                            >
                                                <Translation alias={'view'}/>
                                            </Button>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </>
            : <Loader />}
        </div>
    )
}

export const Portfolio = ( { content } ) => {
    const global = useGlobal();
    const [ main ] = useMain();
    const [ lang ] = useLang();
    const [, setAlertMessage] = useAlertMessage();
    const [portf, setPortf] = useState(null);

    const loadPortfolioContent = async () => {
        let url = global.serverDomain + '/api/portfolio/' + lang;
        const response = await axios.get(url);
        if(response.status === 200 ){
            setPortf(response.data)
        }else{
            setAlertMessage('Error')
        }
    }

    useEffect(() => loadPortfolioContent(), [])

    return (
        <>
            {portf !== null && global !== null ?
                <>
                    <div
                        className={'w-100 bg-light-grey'} 
                        style={
                            { 
                            
                                height: '20vh',
                                backgroundSize:"100% auto",
                                backgroundPosition: "center center",
                                backgroundImage : "url(" + global.serverDomain + main.admin.bgimg + ")"
                            }
                        }>
                    </div>
                    <RenderPortfolio content={portf} global={global} main={main} />
                </>
            :
                <Loader/>
            }
            
            
            
        </>
    )
} 