import { useState } from 'react';
export const useModal = () => {
    const [ modal, setInnerModal ] = useState( false );
    const [ show, setShow ] = useState( false );

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const setModal = (obj) => {
        setInnerModal({header : obj.header, body : obj.body})
    }
   
    return [ modal, setModal, show, handleClose, handleShow ] ;
}
