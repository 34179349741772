import { useState } from 'react';

export const useLang = () =>{
    const [ lang , internalSetLang ] = useState(localStorage.getItem('lang'));

    const setLang = (val) => {
        internalSetLang(val)
        localStorage.setItem('lang', val)
    }

    if(localStorage.getItem('lang') === null){
        setLang('en');
    }
    
    return [ lang, setLang ];

}